<template>
  <div v-if="!loading">
    <v-dialog v-model="showPrepDialog" max-width="600px">
      <v-card>
        <v-card-title>Prep Step for
          {{ prep.ingredient.fooddescriptionsimple || prep.ingredient.fooddescription + "*" }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :disabled="readOnly && !showPrepControls"
            label="Prep Instruction"
            v-model="prep.ingredient.prep_instruction"
          />
          <v-select
            :disabled="readOnly && !showPrepControls"
            clearable
            label="Assigned Team"
            flat
            class="mr-2"
            :items="defaultTeams"
            v-model="prep.ingredient.assigned"
          />
        </v-card-text>
        <v-card-actions v-if="readOnly && !showPrepControls">
          <v-spacer/>
          <v-btn @click="showPrepDialog=false">Close</v-btn>
        </v-card-actions>
        <v-card-actions v-if="!(readOnly && !showPrepControls)">
          <v-btn @click="showPrepDialog=false">Cancel</v-btn>
          <v-spacer/>
          <v-btn @click="savePrep(prep.ingredient)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-simple-table
      dense
    >
      <template v-slot:default>
        <thead>
        <tr>
          <th class="d-print-none" v-if="!dense && showNutrientControls" style="width: 90px">
          </th>
          <th v-if="shouldShowMealCost" :style="`width: ${amountWidth}`" :class="amountHeaderClass">
            Portion (cost)
          </th>
          <th v-if="quantity" :style="`width: ${amountWidth}`" :class="amountHeaderClass">
            Total (raw)
          </th>
          <th v-if="!prepView" :style="`width: ${amountWidth}`" :class="amountHeaderClass">
            Portion (raw)
          </th>
          <th v-if="!prepView" :style="`width: ${amountWidth}`" :class="amountHeaderClass">
            <!--          <a href="https://labelcalc.com/food-labeling/calculating-product-yield-and-weight-loss-percentage-for-beginners/">% Yield</a>-->
            Change %
          </th>
          <th v-if="quantity" :style="`width: ${amountWidth}`" :class="amountHeaderClass">
            Total<br/>
            (cooked)
          </th>
          <th v-if="!prepView" :style="`width: ${amountWidth}`" :class="amountHeaderClass">
            Portion<br/>
            (cooked)
          </th>
          <th class="pl-1" :class="amountHeaderClass" :style="`width: ${ingredientWidth}`">
            Ingredient
          </th>
          <th v-if="!dense" style="width: fit-content">
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="(ingredient,index) of activeIngredients"
          v-bind:key="index"
          :class="[ingredient.isModified?'blue lighten-4':'',ingredient.ignore_prep_weight?'d-print-none':''].join(' ')"
        >
          <td align="right" v-if="!dense && showNutrientControls" class="d-print-none">
            <v-btn
              v-if="showNutrientControls"
              class="d-print-none"
              icon
              small
              @click="showNutrients(ingredient)"
            >
              <v-icon>mdi-gauge</v-icon>
            </v-btn>
          </td>
          <td v-if="shouldShowMealCost" :class="amountClass">
          <span v-if="ingredient.price">
          ${{ (getPortionCost(ingredient)).toFixed(2) }}
          </span>
          </td>

          <td v-if="quantity" :class="amountClass">
            {{ formatWeight(quantity * getRawAmount(ingredient), ['g', 'kg'], [1, 2]) }}
          </td>
          <!--                <td>{{ formatWeight(getQuantity(ingredient.monosized) * ingredient.cookedAmount) }}g</td>-->
          <td v-if="!prepView" :class="amountClass">
            {{ formatWeight(getRawAmount(ingredient), ['g', 'kg'], [1, 2]) }}
          </td>
          <td v-if="!prepView" :class="amountClass">

            <!--          <input style="width: 60px; border-bottom: 2px solid black; text-align: right"-->
            <!--                 type="number"-->
            <!--                 step="10"-->
            <!--                 v-model="ingredient.percentYield"-->
            <!--                 dense-->
            <!--          />-->
            <span v-if="readOnly && ingredient.percentChange && ingredient.percentChange!='0'">
              {{ ingredient.percentChange }}%
            </span>
            <v-text-field v-if="!readOnly"
                          class="table-input"
                          dense
                          type="number"
                          v-model="ingredient.percentChange"
                          label=""
                          suffix="%"
                          @change="updatePercentChange(ingredient)"
                          :disabled="!ingredient.foodid"
            >
            </v-text-field>

          </td>
          <td v-if="quantity" :class="amountClass">
            {{ formatWeight(quantity * ingredient.cookedAmount, ['g', 'kg'], [1, 2]) }}
          </td>
          <td v-if="!prepView" :class="amountClass">
            <span v-if="readOnly">{{ formatWeight(ingredient.cookedAmount, ['g', 'kg'], [1, 2]) }}</span>
            <v-text-field v-if="!readOnly"
                          class="table-input"
                          dense
                          type="number"
                          v-model="ingredient.cookedAmount"
                          label=""
                          suffix="g"
                          :rules="[greaterThanZeroRule]"
                          @change="updateIngredient(ingredient)"
                          @blur="formatCookedAmount(ingredient)"
                          :disabled="!ingredient.foodid"
            >
            </v-text-field>
          </td>
          <td class="align-left pl-1">
            <v-tooltip top v-if="ingredient.ignore_prep_weight">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-eye-off-outline
                </v-icon>
              </template>
              <span>The weight of this ingredient is not included in the prep total weight</span>
            </v-tooltip>


            <span v-if="readOnly || !ingredient.editing" @click="showEditIngredient(ingredient,true)">
            {{ ingredient.fooddescriptionsimple || ingredient.fooddescription + "*" }}
            <span v-if="ingredient.tags">({{ ingredient.tags.join(', ') }})</span>
            <v-icon color="blue" v-if="ingredient.verified">mdi-shield-check</v-icon>
          </span>
            <span v-if="!readOnly && ingredient.editing">
            <IngredientAutocomplete
              :ingredient="ingredient"
              v-on:close.once="updateIngredientFood(ingredient,$event)"
            />
          </span>
            <span @click="!readOnly && showPrep(ingredient)"
                  v-if="(showPrepControls || showPrepStatus) && (ingredient.prep_instruction || ingredient.assigned)"
                  class="caption"><br/>
              <span class="pr-2" v-if="ingredient.prep_instruction">{{ ingredient.prep_instruction }}</span>
              <v-chip x-small v-if="ingredient.assigned">{{ ingredient.assigned }}</v-chip>
            </span>
          </td>
          <td align="right" v-if="!dense">
            <v-btn
              v-if="!readOnly"
              class="d-print-none"
              icon
              small
              @click="toggleIgnore(ingredient)"
            >
              <v-icon v-if="ingredient.ignore_prep_weight">mdi-eye-off-outline</v-icon>
              <v-icon v-if="!ingredient.ignore_prep_weight">mdi-eye-outline</v-icon>
            </v-btn>

            <v-btn
              v-if="!readOnly"
              class="d-print-none"
              icon
              small
              @click="removeIngredient(ingredient)"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>

            <template v-if="showPrepControls || showPrepStatus">
              <v-btn v-if="showPrepControls"
                     class="d-print-none"
                     icon
                     small
                     @click="showPrep(ingredient)"
              >
                <v-icon :color="ingredient.prep_instruction?'blue':''">mdi-knife</v-icon>
              </v-btn>
              <v-icon v-if="!showPrepControls" :color="ingredient.prep_instruction?'blue':''">mdi-knife</v-icon>
              <v-icon @click="saveAssignment(ingredient,'protein')"
                      :class="ingredient.assigned==='protein' || 'd-print-none'"
                      :color="ingredient.assigned==='protein'?'blue':''">mdi-food-steak
              </v-icon>
              <v-icon @click="saveAssignment(ingredient,'starch')"
                      :class="ingredient.assigned==='starch' || 'd-print-none'"
                      :color="ingredient.assigned==='starch'?'blue':''">mdi-rice
              </v-icon>
              <v-icon @click="saveAssignment(ingredient,'veg')"
                      :class="ingredient.assigned==='veg' || 'd-print-none'"
                      :color="ingredient.assigned==='veg'?'blue':''">mdi-sprout
              </v-icon>
              <v-icon @click="saveAssignment(ingredient,'misc')"
                      :class="ingredient.assigned==='misc' || 'd-print-none'"
                      :color="ingredient.assigned==='misc'?'blue':''">mdi-alien
              </v-icon>
              <v-icon @click="saveAssignment(ingredient,null)"
                      class="d-print-none">mdi-cancel
              </v-icon>

            </template>
          </td>
        </tr>
        <tr v-if="ingredients.length===0" style="font-weight: bold">
          <td/>
          <td colspan="4">
            no ingredients yet
          </td>
        </tr>
        <tr v-if="ingredients.length>0" style="font-weight: bold">
          <td v-if="!dense && showNutrientControls" class="d-print-none"/>
          <td v-if="shouldShowMealCost" :class="amountClass">
          <span v-if="portionTotalCost">
            ${{ portionTotalCost }}
          </span>
          </td>
          <td v-if="quantity" :class="amountClass">
            {{ formatWeight(rawTotalSum * quantity, ['g', 'kg'], [1, 2]) }}
          </td>
          <td v-if="!prepView" :class="amountClass">
            {{ formatWeight(rawTotalSum) }}g
          </td>
          <td v-if="!prepView"></td>
          <td v-if="quantity" :class="amountClass">
            {{ formatWeight(cookedAmountTotal * quantity, ['g', 'kg'], [1, 2]) }}
          </td>
          <td v-if="!prepView" align="right" :class="amountClass">
            {{ formatWeightWithUnits(cookedAmountTotal) }}
          </td>
          <td class="align-left pl-1">total weight</td>
          <td v-if="!dense" class="d-print-none"/>
        </tr>

        </tbody>
        <!--      <pre>-->
        <!--        {{ activeIngredients }}-->
        <!--      </pre>-->
      </template>
    </v-simple-table>
  </div>
</template>

<script>

import IngredientAutocomplete from '@/components/IngredientAutocomplete';
import {
  assignIngredientAmounts,
  convertIngredient,
  ensureInt,
  ensureNumber,
  formatWeight,
  formatWeightWithUnits
} from '@/store/utils';
import {mapActions, mapMutations, mapState} from 'vuex';
import api from '@/api';


export default {
  components: {IngredientAutocomplete},
  props: {
    title: String,  // FIXME: not used
    subtitle: String,
    component: Object,
    ingredients: Array,
    summary: Object,
    targetTotalCookedAmount: {type: Number, required: false},
    targetPortionCookedAmount: {type: Number, required: false},
    dense: Boolean,
    mealId: {type: [Number, String]},
    readOnly: {type: Boolean, required: true},
    showMealCost: {type: Boolean, default: false, required: false},
    saveIngredientAssignment: {type: Function, default: null, required: false},
    showNutrientControls: {type: Boolean, default: true, required: false},
    showPrepControls: {type: Boolean, default: false, required: false},
    showPrepStatus: {type: Boolean, default: false, required: false},
    onUpdateIngredient: {type: Function, default: null, required: false},
    sortIngredients: {type: Boolean, default: false, required: false},
  },
  data() {
    return {
      showPrepDialog: false,
      prep: {
        ingredient: false
      },
      ingredientsArray: [],
      editIngredient: {},
      amountWidth: this.dense ? '20px;' : '60px',
      ingredientWidth: this.dense ? '' : 'fit-content',
      amountClass: (this.dense ? 'px-1 py-0 my-0' : '') + ' text-right',
      amountHeaderClass: this.dense ? 'pa-2 text-xs-left' : 'text-left',
      loading: true
    }
  },
  mounted() {
    // this.activeIngredients.forEach(this.formatCookedAmount);
    this.updateIngredientArray(this.ingredients);
    this.loading = false;
  },
  watch: {
    ingredients: 'updateIngredientArray'
  },
  methods: {
    formatWeightWithUnits,
    ...mapActions(['updateIngredient', 'updateIngredientAttribute']),
    ...mapMutations(['SET_MEAL_INGREDIENT']),
    updateIngredientArray(ingredients) {
      function getStepDetail(i) {
        const steps = i.component_food_step || i.meal_food_step;
        if (steps && steps.length > 0) {
          if (steps.length > 1) {
            console.warn('more than one step, using first', i);
          }
          // console.log('set step detail',i.fooddescriptionsimple, steps);
          const [step] = steps;
          return {
            team: step.team,
            assigned: step.team,
            instruction: step.instruction,
            prep_instruction: step.instruction
          }
        } else {
          return {};
        }
      }

      // console.log('update ingredients',ingredients);
      this.ingredientsArray = [...ingredients].map(i => {
        if (i.cookedAmount === undefined) {
          // console.log('ingredient not converted', i);
          i = convertIngredient(i);
        }
        return {
          ...i,
          cookedAmount: formatWeight(i.cookedAmount),
          editing: i.editing || false,
          ...getStepDetail(i)
        };
      });

      if (this.targetPortionCookedAmount !== undefined) {
        const componentPortionAmount = this.ingredientsArray.reduce((sum, i) => sum + Number(i.cookedAmount), 0);
        const scale = this.targetPortionCookedAmount / componentPortionAmount;
        // console.log('componentPortionAmount', componentPortionAmount, this.targetPortionCookedAmount, scale);
        this.ingredientsArray.forEach(i => {
          i.cookedAmount = (i.cookedAmount * scale).toFixed(2);
        })
      }

      if (this.sortIngredients) {
        this.ingredientsArray.sort((a, b) => b.cookedAmount - a.cookedAmount || a.fooddescriptionsimple.localeCompare(b.fooddescriptionsimple));
      }
    },
    greaterThanZeroRule(value) {
      return this.greaterThanZero(value) || 'value must be greater than zero';
    },
    greaterThanZero(value) {
      value = parseFloat(value);
      return value > 0;
    },
    formatWeight,
    formatCookedAmount(i) {
      // i.cookedAmount = this.formatWeight(i.cookedAmount);
      // if (i.cookedAmount >= 0.1) {
      const formatted = this.formatWeight(i.cookedAmount)
      this.$set(i, 'cookedAmount', formatted);
      // }
    },
    getRawAmount(ingredient) {
      let {cookedAmount, percentChange} = ingredient;
      cookedAmount = ensureNumber(cookedAmount);
      percentChange = ensureNumber(percentChange)
      // if (ingredient.percentYield) {
      //   return (cookedAmount / (ingredient.percentYield / 100));
      // }
      if (percentChange) {
        return cookedAmount + (cookedAmount * (percentChange / 100));
      } else {
        return cookedAmount;
      }
    },
    formatPercent(value) {
      if (value) {
        return this.formatWeight(100 * value) + '%'
      }
    },
    sum(array) {
      return array.reduce((ac, i) => ac + ensureNumber(i), 0);
    },

    showEditIngredient(ingredient, show) {
      ingredient.editing = show;
    },
    setIngredient(index, ingredient) {
      console.log('saving ', [index, ingredient]);
      this.$set(this.ingredients, index, ingredient);
    },
    updateIngredientFood(oldIngredient, newIngredient) {
      this.$set(oldIngredient, 'editing', false);
      if (oldIngredient.foodid === newIngredient.foodid) {
        console.log('no change', {oldIngredient, newIngredient});
        return;
      }
      //ensure the ingredient refers to this meal
      oldIngredient.mealid = this.mealId;
      this.$nextTick(async () => {
        console.log('updateIngredient(ingredient, update)', {oldIngredient, newIngredient});
        try {
          assignIngredientAmounts(newIngredient, oldIngredient);
          //ensure the ingredient refers to this meal
          newIngredient.mealid = this.mealId;
          newIngredient.isModified = true;
          await this.$store.dispatch('replaceIngredientFood', {oldIngredient, newIngredient});

          if (this.onUpdateIngredient) {
            this.onUpdateIngredient(newIngredient);
          }
        } catch (e) {
          console.warn('fail', e);
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    async updateIngredient(ingredient) {
      ingredient.isModified = true;
      let newAmount = ingredient.cookedAmount;
      if (!this.greaterThanZero(newAmount)) {
        return;
      }
      console.log('updating updateIngredient', {name: ingredient.fooddescriptionsimple, newAmount});
      this.$nextTick(async () => {
        this.$set(ingredient, 'editing', false);
        await this.$store.dispatch('updateIngredientAmount', {ingredient});
        if (this.onUpdateIngredient) {
          this.onUpdateIngredient(ingredient);
        }
      });
    },
    removeIngredient(ingredient) {
      // console.log('TODO: removing ', [index, this.ingredients[index]]);
      // this.$delete(this.ingredients,index);
      this.$set(ingredient, 'toDelete', true);
      this.$store.dispatch('removeIngredient', {ingredient});
      if (this.onUpdateIngredient) {
        this.onUpdateIngredient({...ingredient, cookedAmount: 0});
      }
    },
    updatePercentChange(ingredient) {
      ingredient.isModified = true;
      this.$nextTick(async () => {
        this.$set(ingredient, 'editing', false);
        await this.$store.dispatch('updatePercentChange', {ingredient});
      });
      if (this.onUpdateIngredient) {
        this.onUpdateIngredient(ingredient);
      }
    },
    showNutrients(ingredient) {
      const ingredientRoute = {
        name: 'IngredientNutrients',
        query: Object.fromEntries([[ingredient.foodid, ingredient.amount_in_grams || ingredient.cookedAmount]])
      };
      console.log('ingredientRoute', {ingredient, ingredientRoute});
      this.$router.push(ingredientRoute);
    },
    getPortionCost(ingredient) {
      if (ingredient.price || ingredient.price === 0) {
        return (this.getRawAmount(ingredient) / 1000 * ingredient.price) / 100;
      }
    },
    showPrep(ingredient) {
      console.log('show prep ', ingredient);
      this.showPrepDialog = true;
      this.prep.ingredient = ingredient;
      // this.$set(this, 'prep', ingredient);
      // if (ingredient.prep_instruction) {
      //   if (!confirm('do you want to overwrite the current prep instructions?\n' + ingredient.prep))
      //     return;
      // }
      // ingredient.prep_instruction = prompt('enter the prep instruction for ' + ingredient.name)
      //
    },
    savePrep(ingredient) {
      const payload = {
        foodid: ensureInt(ingredient.foodid || ingredient.food_id),
        component_id: ingredient.component_id,
        prep_instruction: ingredient.prep_instruction
      };
      console.log('saving', payload);
      const mealId = this.mealId;
      if (this.saveIngredientAssignment) {
        this.saveIngredientAssignment({
          id: ingredient.id,
          team: ingredient.assigned,
          instruction: ingredient.prep_instruction,
          food_id: ensureInt(ingredient.foodid),
          mealId
        });
      }
      if (mealId) {
        return api.post(`v2/meal/${mealId}/ingredients`, payload).then(() => {
          // console.log('saved', r);
          this.SET_MEAL_INGREDIENT({ingredient, update: {prep_instruction: ingredient.prep_instruction}});
        })
          .finally(() => {
            this.showPrepDialog = false;
          });
      } else {
        console.log('no meal id, so not saving')
        // this.SET_MEAL_INGREDIENT({ingredient, update: {prep_instruction: ingredient.prep_instruction}});
        this.showPrepDialog = false;
      }
    },
    saveAssignment(ingredient, team) {
      console.log('save', ingredient, team);
      // if (this.readOnly && !this.showPrepControls) {
      if (!this.showPrepControls) {
        console.log('skiping as showPrepControls is false');
        return;
      }
      this.saveIngredientAssignment({
        id: ingredient.id,
        food_id: ingredient.foodid,
        team,
        instruction: ingredient.prep_instruction,
        mealId: this.mealId
      }).then(() => {
        this.$set(ingredient, 'assigned', team);
//        console.log('done', {r, ingredient});
      });
    },
    toggleIgnore(ingredient) {
      ingredient.isModified = true;
      const newValue = !ingredient.ignore_prep_weight;
      ingredient.ignore_prep_weight = newValue;
      this.$nextTick(async () => {
        this.$set(ingredient, 'editing', false);
        return this.updateIngredientAttribute({ingredient, update: {ignore_prep_weight: newValue}});
      });
    },
  },
  computed: {
    ...mapState(['defaultTeams']),
    prepView() {
      return this.$route.query.prep === '1';
    },
    activeIngredients() {
      return this.ingredientsArray
        // .filter(d => this.readOnly ? !d.ignore_prep_weight : true)
        .filter(i => !i.toDelete);
    },
    quantity() {
      if (this.targetTotalCookedAmount) {
        return this.targetTotalCookedAmount / this.cookedAmountTotal;
      } else if (this.summary) {
        const summary = this.summary;
        let small = summary.small || 0;
        let large = summary.large || 0;
        const medium = summary.medium || 0;

        if (this.component.monosized) {
          return small + medium + large;
        } else {
          small = small * (this.component.small_ratio || 0.75);
          large = large * (this.component.large_ratio || 1.25);
          return small + medium + large;
        }
      } else {
        return 0;
      }
    },
    cookedAmountTotal() {
      return this.sum(
        this.activeIngredients
          .map(d =>
            d.ignore_prep_weight
              ? 0
              : d.cookedAmount
          ));
    },
    rawTotalSum() {
      return this.sum(
        this.activeIngredients
          .filter(d => !d.ignore_prep_weight)
          .map(this.getRawAmount));
    },
    portionTotalCost() {
      const portionCosts = this.activeIngredients.map(this.getPortionCost).filter(c => !!c);
      if (portionCosts.length > 0) {
        return this.sum(portionCosts).toFixed(2);
      } else {
        return undefined;
      }
    }
    ,
    shouldShowMealCost() {
      return !this.dense && this.showMealCost && this.portionTotalCost;
    }
  }
}

</script>

<style>


td {
  border: 2px;
}

tr:nth-child(even) {
  background: #fafafa
}

.table-input {
  height: 30px;
  width: 60px;
  font-size: 14px;

}

.table-input .v-text-field__slot input {
  text-align: right;
}


/* hide the spinner */
/* Chrome, Safari, Edge, Opera */
.v-text-field__slot input::-webkit-outer-spin-button,
.v-text-field__slot input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;

}

/* Firefox */
.v-text-field__slot input[type=number] {
  -moz-appearance: textfield;

}
</style>
